:root {
    --primary-color: #1a1a1a;
    --secondary-color: #ffffff;
    --accent-color: #720FFF;
    --text-color: #333333;
    --background-color: #f5f5f5;
}

html, body{
    padding: 0 !important;
    margin: 0 !important;
    min-height: 100vh;
}

body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
    /*user-select: none;*/
}

.container {
    max-width: 60%;
    margin: 0 auto;
    padding: 0 20px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header {
    background: linear-gradient(135deg, rgba(5, 5, 5, 0.95) 0%, rgba(20, 20, 20, 0.95) 100%);
    position: relative;
    overflow: hidden;
    padding: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.header::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle at 20% 35%, rgba(114, 15, 255, 0.1) 0%, transparent 30%),
    radial-gradient(circle at 75% 65%, rgba(255, 0, 255, 0.1) 0%, transparent 30%);
    filter: blur(25px);
    opacity: 0.5;
}

@keyframes subtle-move {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(5%, 5%);
    }
}

/* Rest of the CSS remains the same */

.header .container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 60%;
    margin: 0 auto;
    padding: 0 20px;
}

.nav {
    display: flex;
    align-items: center;
}

.nav a {
    color: var(--secondary-color);
    text-decoration: none;
    margin-left: 30px;
    font-weight: 500;
    transition: all 0.3s ease;
    font-size: 1.25rem;
    padding: 8px 15px;
    position: relative;
}

.nav a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #720FFF, #FF00FF);
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.nav a:hover::after {
    transform: scaleX(1);
}

/* Add a subtle glow to the logo */
.header .container > :first-child {
    filter: drop-shadow(0 0 8px rgba(114, 15, 255, 0.3));
    transition: filter 0.3s ease;
}

.header .container > :first-child:hover {
    filter: drop-shadow(0 0 12px rgba(114, 15, 255, 0.5));
}

.hero {
    background-image: url("../public/svgs/large-triangles.svg");
    background-color: var(--primary-color);
    background-size: cover;
    color: var(--secondary-color);
    padding: 100px 0;
}

.hero-content {
    max-width: 600px;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.highlight {
    color: var(--accent-color);
}

.process {
    padding: 100px 0;
}

.process h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 50px;
}

.process-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    gap: 30px;
}

.process-card {
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.process-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.process-card img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.process-card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.footer {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    text-align: center;
    padding: 20px 0;
    width: 100%;
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 2.5rem;
    }

    .process-grid {
        grid-template-columns: 1fr;
    }
}

.ai-explainer {
    padding: 80px 0;
    position: relative;
    overflow: hidden;
}

.ai-explainer::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle at 20% 20%, rgba(114, 15, 255, 0.15) 0%, transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(255, 0, 255, 0.15) 0%, transparent 40%);
    filter: blur(50px);
    opacity: 0.5;
}

.ai-explainer h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 50px;
    position: relative;
}

.ai-explainer h2::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, #720FFF, #FF00FF);
}

.explainer-content {
    display: flex;
    gap: 40px;
    align-items: flex-start;
}

.explainer-text, .explainer-benefits {
    flex: 1;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 30px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.explainer-text:hover, .explainer-benefits:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.explainer-text h3, .explainer-benefits h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: var(--accent-color);
}

.explainer-text p {
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.explainer-benefits ul {
    list-style-type: none;
    padding: 0;
}

.explainer-benefits li {
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 10px;
    padding-left: 25px;
    position: relative;
}

.explainer-benefits li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--accent-color);
}

@media (max-width: 968px) {
    .explainer-content {
        flex-direction: column;
    }
}

.cta-section {
    background-color: var(--primary-color);
    padding: 80px 0;
    position: relative;
    overflow: hidden;
}

.cta-section::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle at 30% 40%, rgba(114, 15, 255, 0.1) 0%, transparent 30%),
    radial-gradient(circle at 70% 60%, rgba(255, 0, 255, 0.1) 0%, transparent 30%);
    filter: blur(30px);
    opacity: 0.6;
}

.cta-box {
    background: linear-gradient(135deg, rgba(20, 20, 20, 0.8) 0%, rgba(40, 40, 40, 0.8) 100%);
    border-radius: 15px;
    padding: 40px;
    text-align: center;
    position: relative;
    z-index: 1;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.cta-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(255, 255, 255, 0.05) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(255, 255, 255, 0.05) 75%);
    background-size: 20px 20px;
    opacity: 0.1;
    z-index: -1;
}

.cta-box::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle, rgba(114, 15, 255, 0.1) 0%, transparent 70%);
    opacity: 0.4;
    z-index: -1;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}

.cta-box h2 {
    color: var(--secondary-color);
    font-size: 2.5rem;
    margin-bottom: 20px;
    position: relative;
}

.cta-box h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background: linear-gradient(90deg, #720FFF, #FF00FF);
}

.cta-box p {
    color: var(--secondary-color);
    font-size: 1.4rem;
    margin: 20px auto;
    width: 80%;
    line-height: 1.6;
    position: relative;
    z-index: 2;
}

.cta-button {
    font-family: 'Poppins', sans-serif;
    background: var(--accent-color);
    color: var(--secondary-color);
    border: none;
    padding: 14px 32px;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    letter-spacing: 1px;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(114, 15, 255, 0.2);
}

.cta-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.3),
            transparent
    );
    transition: left 0.7s ease;
}

.cta-button:hover {
    background: #8A2BE2; /* A slightly lighter purple for hover state */
    box-shadow: 0 6px 12px rgba(114, 15, 255, 0.3);
    transform: translateY(-2px);
}

.cta-button:hover::before {
    left: 100%;
}

.cta-button:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(114, 15, 255, 0.2);
}

.testimonials-section {
    background-color: var(--primary-color);
    padding: 80px 0;
    position: relative;
    overflow: hidden;
}

.testimonials-section::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle at 30% 40%, rgba(114, 15, 255, 0.1) 0%, transparent 30%),
    radial-gradient(circle at 70% 60%, rgba(255, 0, 255, 0.1) 0%, transparent 30%);
    filter: blur(30px);
    opacity: 0.6;
}

.testimonials-section h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 50px;
    color: var(--secondary-color);
    position: relative;
}

.testimonials-section h2::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, #720FFF, #FF00FF);
}

.testimonial-carousel {
    position: relative;
    height: 250px;
    overflow: hidden;
}

.testimonial-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(20, 20, 20, 0.8) 0%, rgba(40, 40, 40, 0.8) 100%);
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.5s ease;
}

.testimonial-card.active {
    opacity: 1;
}

.testimonial-content {
    color: var(--secondary-color);
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.testimonial-author {
    display: flex;
    align-items: center;
}

.author-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.author-info h4 {
    color: var(--secondary-color);
    font-size: 1.1rem;
    margin: 0;
}

.author-info p {
    color: var(--accent-color);
    font-size: 0.9rem;
    margin: 0;
}

.testimonial-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

.dot.active {
    opacity: 1;
    background-color: var(--accent-color);
}

@media (max-width: 768px) {
    .testimonial-carousel {
        height: 300px;
    }

    .testimonial-content {
        font-size: 1rem;
    }
}