@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.logo-container {
    display: inline-block;
    cursor: pointer !important;
}

.logo {
    text-decoration: none;
    display: flex;
    align-items: baseline;
}

.logo-text, .logo-o {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 3.75rem;
    letter-spacing: -0.03em;
}

.logo-text {
    color: var(--secondary-color);
    margin-right: -0.1em;
}

.logo-o {
    color: var(--accent-color);
    /*transform: translateY(0.05em);*/
    transform: translateX(0.05em);
}

.logo-container {
    position: relative;
    overflow: hidden;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.logo-container::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    filter: blur(10px);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.logo-container:hover::before {
    opacity: 1;
}

.logo:hover .logo-text {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.logo:hover .logo-o {
    text-shadow: 0 0 10px rgba(114, 15, 255, 0.5);
}

@media (max-width: 768px) {
    .logo-text, .logo-o {
        font-size: 2rem;
    }
    .logo-o {
        font-size: 2.2rem;
    }
}