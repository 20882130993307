.contact-section {
    padding: 100px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    height: 55vh;
}

.contact-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 20% 20%, rgba(114, 15, 255, 0.15) 0%, transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(255, 0, 255, 0.15) 0%, transparent 40%);
    filter: blur(50px);
    opacity: 0.5;
    z-index: -1;
}
.contact-section h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: var(--primary-color);
}

.contact-section p {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: var(--text-color);
}

.contact-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.contact-buttons .cta-button {
    text-decoration: none;
    display: inline-block;
}

@media (max-width: 768px) {
    .contact-buttons {
        flex-direction: column;
        align-items: center;
    }

    .contact-buttons .cta-button {
        width: 80%;
    }
}