body {
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
}

.chatgpt-dev-container {
    max-width: 60%;
    margin: 0 auto;
    padding: 40px 20px;
}

.chatgpt-dev-header {
    background: linear-gradient(135deg, rgba(5, 5, 5, 0.95) 0%, rgba(20, 20, 20, 0.95) 100%);
    position: relative;
    overflow: hidden;
    padding: 40px 0;
    text-align: center;
    color: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.chatgpt-dev-header::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle at 20% 35%, rgba(16, 163, 127, 0.15) 0%, transparent 30%),
    radial-gradient(circle at 75% 65%, rgba(16, 163, 127, 0.15) 0%, transparent 30%);
    filter: blur(25px);
    opacity: 0.5;
}

.chatgpt-dev-header h1 {
    font-size: 2.5rem;
    margin: 0;
    position: relative;
    z-index: 1;
}

.chatgpt-dev-content section {
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chatgpt-dev-content section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.chatgpt-dev-content h2 {
    color: var(--accent-color);
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 20px;
}

.chatgpt-dev-content h3 {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin-top: 25px;
    margin-bottom: 15px;
}

.chatgpt-dev-content h4 {
    color: var(--accent-color);
    font-size: 1.3rem;
    margin-top: 20px;
    margin-bottom: 10px;
}

.chatgpt-dev-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.chatgpt-dev-content ul {
    list-style-type: none;
    padding: 0;
}

.chatgpt-dev-content li {
    margin-bottom: 20px;
    padding-left: 30px;
    position: relative;
}

.chatgpt-dev-content li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--accent-color);
    font-weight: bold;
}

.chatgpt-dev-benefits li h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.chatgpt-dev-benefits li p {
    margin-top: 0;
}

.chatgpt-dev-reliability ul {
    padding-left: 20px;
}

.chatgpt-dev-reliability li {
    list-style-type: disc;
    padding-left: 10px;
}

.chatgpt-dev-reliability li::before {
    content: none;
}

.chatgpt-dev-future {
    background-color: var(--primary-color) !important;
    color: var(--secondary-color) !important;
    position: relative;
    overflow: hidden;
}

.chatgpt-dev-future::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle at 30% 40%, rgba(16, 163, 127, 0.1) 0%, transparent 30%),
    radial-gradient(circle at 70% 60%, rgba(16, 163, 127, 0.1) 0%, transparent 30%);
    filter: blur(30px);
    opacity: 0.6;
    z-index: 0;
}

.chatgpt-dev-future h2,
.chatgpt-dev-future p {
    color: var(--secondary-color) !important;
    position: relative;
    z-index: 1;
}

img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .chatgpt-dev-container {
        max-width: 90%;
    }

    .chatgpt-dev-header h1 {
        font-size: 2rem;
    }

    .chatgpt-dev-content h2 {
        font-size: 1.8rem;
    }

    .chatgpt-dev-content h3 {
        font-size: 1.3rem;
    }

    .chatgpt-dev-content h4 {
        font-size: 1.2rem;
    }

    .chatgpt-dev-content p {
        font-size: 1rem;
    }
}