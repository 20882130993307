
.portfolio-page {
    background-color: var(--background-color) !important;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.portfolio-container {
    max-width: 60%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    padding: 100px 20px;
}

.portfolio-page::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle at 20% 20%, rgba(114, 15, 255, 0.05) 0%, transparent 40%),
                radial-gradient(circle at 80% 80%, rgba(255, 0, 255, 0.05) 0%, transparent 40%);
    filter: blur(50px);
    opacity: 0.5;
    z-index: 0;
}

.portfolio-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 50px;
    color: var(--primary-color);
    position: relative;
    z-index: 1;
}

.portfolio-title::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, #720FFF, #FF00FF);
}

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    position: relative;
    z-index: 1;
}

.portfolio-item {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(114, 15, 255, 0.2);
}

.portfolio-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.portfolio-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.portfolio-item:hover .portfolio-image img {
    transform: scale(1.05);
}

.portfolio-content {
    padding: 20px;
}

.portfolio-content h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: var(--primary-color);
}

.portfolio-content a {
    color: var(--accent-color);
    text-decoration: none;
    font-size: 1.2rem;
    margin-bottom: 15px;
    display: inline-block;
    position: relative;
}

.portfolio-content a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #720FFF, #FF00FF);
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.portfolio-content a:hover::after {
    transform: scaleX(1);
}

.portfolio-content p {
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 1.6;
}

@media (max-width: 768px) {
    .portfolio-container {
        max-width: 90%;
        padding: 60px 20px;
    }

    .portfolio-title {
        font-size: 2.5rem;
    }

    .portfolio-grid {
        grid-template-columns: 1fr;
    }
}

.portfolio-content p {
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 1.6;
    margin-bottom: 15px;
}

.portfolio-tag {
    background-color: rgba(114, 15, 255, 0.1);
    color: #720FFF;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
    transition: background-color 0.2s ease, color 0.2s ease;
    border: 1px solid rgba(114, 15, 255, 0.2);
}

.portfolio-tag:hover {
    background-color: rgba(114, 15, 255, 0.2);
    color: #5700C2;
}

.portfolio-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
}


@media (max-width: 768px) {
    .portfolio-container {
        max-width: 90%;
        padding: 60px 20px;
    }

    .portfolio-title {
        font-size: 2.5rem;
    }

    .portfolio-grid {
        grid-template-columns: 1fr;
    }

    .portfolio-tag {
        font-size: 0.8rem;
    }
}