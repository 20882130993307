.why-zero-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Poppins', sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
}

.why-zero-header {
    text-align: center;
    margin-bottom: 40px;
}

.why-zero-header h1 {
    font-size: 3rem;
    color: var(--primary-color);
    position: relative;
    display: inline-block;
}

.why-zero-header h1::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, var(--accent-color), #FF00FF);
}

.why-zero-content section {
    margin-bottom: 40px;
}

.why-zero-content h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-top: 0px;
    margin-bottom: 20px;
}

.why-zero-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.why-zero-benefits ul,
.why-zero-approach ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.why-zero-benefits li,
.why-zero-approach li {
    font-size: 1.1rem;
    margin-bottom: 15px;
    padding-left: 30px;
    position: relative;
}

.why-zero-benefits li::before,
.why-zero-approach li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--accent-color);
    font-weight: bold;
}

.why-zero-approach {
    background-color: rgba(114, 15, 255, 0.05);
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.why-zero-approach p:last-child {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .why-zero-container {
        padding: 20px;
    }

    .why-zero-header h1 {
        font-size: 2.5rem;
    }

    .why-zero-content h2 {
        font-size: 1.8rem;
    }

    .why-zero-content p,
    .why-zero-benefits li,
    .why-zero-approach li {
        font-size: 1rem;
    }

    .why-zero-benefits li,
    .why-zero-approach li {
        margin-bottom: 10px;
    }
}